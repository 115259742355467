<template>
  <div>
    <vx-card>
      <h4 class="mb-5">Please fill the information for About us.</h4>
      <!--image-->
      <div class="mb-5">
        <!-- Image Container -->
        <div class="vx-col w-full mt-5 vs-con-loading__container">
          <template v-if="image">
            <!-- Image Container -->
            <div class="w-1/2 mb-5">
              <img :src="image" alt="img" class="responsive" style="height:400px; width: auto;">
            </div>
            <!-- Image upload Buttons -->
            <div class="modify-img flex justify-between my-5 w-1/2">
              <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
              <vs-button class="mr-4"  @click="$refs.updateImgInput.click()">Update Image</vs-button>
              <vs-button color="danger" @click="image = null">Remove Image</vs-button>
            </div>
          </template>

          <div class="upload-img my-5" v-if="!image">
            <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
            <vs-button @click="$refs.uploadImgInput.click()">Upload Image</vs-button>
          </div>
        </div>
      </div>
      <!-- title -->
      <div class="input-wrap w-full mb-10">
        <vs-input
          name="title"
          v-model="aboutUsData.title"
          v-validate="'required'"
          data-vv-validate-as="Title"
          label-placeholder="Title"
          class="w-1/2"
        />
        <span class="text-danger text-sm">{{ errors.first('title') }}</span>
      </div>
      <!-- description-->
      <div class="input-wrap w-1/2 mb-10">
        <label class="ml-2">Description</label>
        <quillEditor :pages="description"></quillEditor>
        <span class="text-danger text-sm">{{ errors.first('description') }}</span>
      </div>

      <!--website link-->
      <div class="input-wrap w-full mb-10">
        <vs-input
          name="websiteLink"
          v-model="aboutUsData.websiteLink"
          v-validate="'required'"
          data-vv-validate-as="Website link"
          label-placeholder="Website link"
          class="w-1/2"
        />
        <span class="text-danger text-sm">{{ errors.first('websiteLink') }}</span>
      </div>

      <div class="w-full">
        <vs-button :disabled="!isFormValid" @click="submitHandler">Save</vs-button>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import vSelect from "vue-select";
  import quillEditor from "../../../Editor";

  export default {
    components: {
      "v-select": vSelect,
      quillEditor
    },
    data() {
      return {
        aboutUsData: {
          title: '',
          bannerPhoto: null,
          description: '',
          websiteLink: ''
        },
        centerId: '',
        description: { content: null },
        image: null,
      }
    },
    computed: {
      isFormValid() {
        return !this.errors.any()
          && this.aboutUsData.title
          && this.description.content
          && this.aboutUsData.websiteLink
          && this.image
      }
    },
    methods: {
      ...mapActions("centerAdmin", [
        "createAboutUs"
      ]),
      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: color,
        });
      },
      updateCurrImg(input) {
        if (input.target.files && input.target.files[0]) {
          this.aboutUsData.bannerPhoto = input.target.files[0];
          var reader = new FileReader()
          reader.onload = e => {
            this.image = e.target.result
          }
          reader.readAsDataURL(input.target.files[0])
        }
      },
      async submitHandler() {
        await this.$validator.validateAll().then(result => {
          if (result) {
            let data = new FormData();
            data.append('learningCenterId', this.centerId);
            data.append('title', this.aboutUsData.title);
            data.append('description', this.description.content);
            data.append('websiteLink', this.aboutUsData.websiteLink);
            data.append('bannerPhoto', this.aboutUsData.bannerPhoto);

            this.$vs.loading();
            this.createAboutUs(data).then((res) => {
              if (res.status === 200) {
                this.$vs.loading.close();
                this.showMessage(
                  "Success",
                  "About us details saved successfully.",
                  "success"
                );
                this.$router.push({ name: 'about-us-center-admin' });
              }
            })
              .catch(err => {
                this.$vs.loading.close()
              })
          }
        })
      },
    },
    async created() {
      this.centerId = this.$route.params.id;
    }
  }
</script>


